@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');


body {
    margin: 0;
    font-family: "Graphik LCG", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
}

:root {
    --accent-color: #ff6b00;
    --common-font: 'Inter', sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    flex: 1 1 auto;
}

@font-face {
    font-family: 'Graphik LCG';
    src: local('Graphik LCG'), url(../fonts/GraphikLCG-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Graphik LCG';
    src: local('Graphik LCG'), url(../fonts/GraphikLCG-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Graphik LCG'), url(../fonts/Montserrat-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Graphik LCG'), url(../fonts/Montserrat-Medium.ttf) format('truetype');
    font-weight: 600;
}

